import React, { useContext } from "react"

import SearchMedsMobileFmc from "../../../Epharmacy/SearchMedsMobileFmc"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"
import { useFmcProducts } from "./hooks/useFmcProducts"

const FlexmedOrderMedsMobile = ({ pageContext }) => {
  const data = useFmcProducts()
  let dataMap = data.allAirtableallSitelFmcProducts.nodes.map(
    (node) => node.data
  )

  // transform data before passing to epharmacyFmc
  dataMap = dataMap.map(reformatAirtableData)
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )

  return (
    <SearchMedsMobileFmc
      pageContext={pageContext}
      data={dataMap}
      lookupKeys={["brand", "molecule"]}
      searchLabel="Search medicines"
      flexmedCashlessState={flexmedCashlessState}
      flexmedCashlessDispatch={flexmedCashlessDispatch}
    />
  )
}

// change data format from Airtable to fit fuzzySearch
const reformatAirtableData = (dataMapElement) => {
  let jsonFormat = {
    brand: dataMapElement?.brand,
    molecule: dataMapElement?.molecule,
    price_list: {
      name: dataMapElement?.priceList,
      vat: dataMapElement?.vat,
      vatex_unit_price: dataMapElement?.vatexUnitPrice,
    },
    productTitle: dataMapElement.productTitle?.[0],
    rxRequired: dataMapElement.rxRequired?.[0],
    DrugName: dataMapElement?.DrugName?.[0],
    skuTags: dataMapElement?.skuTags,
    strength: dataMapElement?.strength,
  }

  return jsonFormat
}

export default FlexmedOrderMedsMobile
