import { useStaticQuery, graphql } from "gatsby"

const ALL_FLEXMED_CASHLESS_PRODUCTS = graphql`
  query {
    allAirtableallSitelFmcProducts(
      filter: { data: { ShowOnWebsite: { eq: true } } }
    ) {
      nodes {
        data {
          brand: Brand
          molecule: Molecule
          productTitle: ProductTitle
          rxRequired: RxRequired
          priceList: PriceList
          form: Form
          strength: Strength
          vatexUnitPrice: VatexUnitPrice
          vat: Vat
          skuTags: SKUTags
        }
      }
    }
  }
`

export const useFmcProducts = () => {
  const data = useStaticQuery(ALL_FLEXMED_CASHLESS_PRODUCTS)

  return data
}
